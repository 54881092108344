import React, {useCallback, useContext, useEffect, useState} from "react"
import { Page, Layout, LegacyCard, Icon, LegacyStack, TextContainer, Text, Button } from "@shopify/polaris";
import styled from 'styled-components'
import { navigate } from "gatsby";
import {
    SettingsIcon,
    CreditCardIcon,
    ProductAddIcon,
    TransferInternalIcon,
    PaintBrushFlatIcon,
    ExternalIcon,
} from "@shopify/polaris-icons";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import FirebaseContext from '../../../providers/firebase';
import MigrationWizardModal from "../offers/components/migrationWizardModal/migrationWizardModal";
import getRules from "../../../helpers/getRules";
const SettingWrapper = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .setting-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        .setting-icon {
            padding: 6px;
            background: #C9CCCF;
            border: 1px solid #C9CCCF;
            border-radius: 5px;
            .Polaris-Icon {
                width: 3rem;
                height: 3rem;
            }
        }
        p {
            margin-top: 0;
        }
        
    }
`

function Settings(props) {
    const { chartDateRange, selectedTabIndex, setSelectedTabIndex } = props;
    
    const [fetchedRules, setFetchedRules] = useState([]);
    const { firebase, shop, token, host } = useContext(FirebaseContext);
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(shopDoc);
    useEffect(() => {
        getRules(token, shop, host)
          .then((res) => {
              setFetchedRules(res.data.rules);
          });
    }, [setFetchedRules]);

    const domain = shopData && shopData.shopData && shopData.shopData.domain
        return (
            <Page title="Settings">
                <Layout>
                    <Layout.Section variant="oneThird">
                    <TextContainer>
                        <Text variant="headingMd" as="h2">
                            Checkout Extensibility offer position
                        </Text>
                        <Text as="p">
                            Select where your checkout offers show
                        </Text>
                    </TextContainer>
                    </Layout.Section>
                    <Layout.Section>
                        <LegacyCard sectioned>
                        <LegacyStack  vertical spacing="loose">
                        <TextContainer>
                            <Text as="p" fontWeight="bold">
                                Enable the checkout and custom content extension in your checkout
                            </Text>
                            <Text tone="subdued" as="p">
                                Enable this setting 
                            </Text>
                        </TextContainer>
                        <Button icon={ExternalIcon} onClick={
                                        () => {
                                            window.open(`https://${domain}/admin/settings/checkout/editor`, '_blank')
                                        }
                                        }>Go to checkout editor</Button>
                        </LegacyStack>
                        </LegacyCard>
                    </Layout.Section>
                    <Layout.Section variant="oneThird">
                    <TextContainer>
                        <Text variant="headingMd" as="h2">
                            Post-purchase settings
                        </Text>
                        <Text as="p">
                        Choose UpsellPlus in your post-purchase settings
                        </Text>
                    </TextContainer>
                    </Layout.Section>
                    <Layout.Section>
                        <LegacyCard sectioned>
                        <LegacyStack  vertical spacing="loose">
                        <TextContainer>
                            <Text as="p" fontWeight="bold">
                            Add tracking scripts and other customizations
                            </Text>
                            <Text tone="subdued" as="p">
                            Select ‘UpsellPlus’ app to add features at checkout after payment
                            </Text>
                        </TextContainer>
                        <Button icon={ExternalIcon} onClick={
                                        () => {
                                            window.open(`https://${domain}/admin/settings/checkout#additionalscripts`, '_blank')
                                        }
                                        }>Go to checkout editor</Button>
                        </LegacyStack>
                        </LegacyCard>
                    </Layout.Section>
                {domain &&
                    <Layout.Section variant="oneThird">
                    <TextContainer>
                        <Text variant="headingMd" as="h2">
                        Migration wizard
                        </Text>
                        <Text as="p">
                        Migrate your checkout.liquid upsells to checkout extensibility
                        </Text>
                    </TextContainer>
                    </Layout.Section>
                    }
                    {domain &&
                    <Layout.Section>
                        <LegacyCard sectioned>
                        <LegacyStack  vertical spacing="loose">
                        <TextContainer>
                            <Text as="p" fontWeight="bold">
                            Select the checkout upsells to migrate to the new checkout format
                            </Text>
                            <Text tone="subdued" as="p">
                            The new checkout syncs with your checkout styling. You will have the ability to customize the text and button color once the migration is done.
                            </Text>
                        </TextContainer>
                        <MigrationWizardModal
                          rules={
                            fetchedRules ? fetchedRules.filter((rule) => rule.offerType === "Checkout"
                                && (!rule?.checkoutType || rule?.checkoutType !== "extension" )) : []
                          }
                          shop={shop}
                          firebase={firebase}
                        />
                        </LegacyStack>
                        </LegacyCard>
                    </Layout.Section>
                }
                {shopData && shopData.chargeId && !shopData.planPrice &&
                    <Layout.Section variant="oneThird">
                    <TextContainer>
                        <Text variant="headingMd" as="h2">
                        Billing
                        </Text>
                        <Text as="p">
                        View your billing and subscription charges
                        </Text>
                    </TextContainer>
                    </Layout.Section>
            }
                    {shopData && shopData.chargeId && !shopData.planPrice &&
                    <Layout.Section>
                        <LegacyCard sectioned>
                        <LegacyStack  vertical spacing="loose">
                        <TextContainer>
                            <Text as="p" fontWeight="bold">
                            View current plan and browse through more plans
                            </Text>
                            <Text tone="subdued" as="p">
                            You'll see when your subscription plan will be upgraded
                            </Text>
                        </TextContainer>
                        <Button onClick={
                                        () => {
                                            navigate(
                                            `/app/settings/billing`,
                                            {
                                                replace: true,
                                            }
                                            )
                                        }
                                        }>View Billing</Button>
                        </LegacyStack>
                        </LegacyCard>
                    </Layout.Section>
                }
                    <Layout.Section variant="oneThird">
                    <TextContainer>
                        <Text variant="headingMd" as="h2">
                        Checkout branding
                        </Text>
                        <Text as="p">
                        Control checkout branding, style and colors
                        </Text>
                    </TextContainer>
                    </Layout.Section>
                    <Layout.Section>
                        <LegacyCard sectioned>
                        <LegacyStack  vertical spacing="loose">
                        <TextContainer>
                            <Text as="p" fontWeight="bold">
                            Make changes to your checkout branding
                            </Text>
                            <Text tone="subdued" as="p">
                            Make changes to your checkout branding
                            </Text>
                        </TextContainer>
                        <Button onClick={
                                        () => {
                                            navigate(
                                                `/app/settings/branding`,
                                                {
                                                    state: {
                                                        backLink: "settings",
                                                    },
                                                    replace: true,
                                                }
                                                )
                                        }
                                        }>Go to Checkout branding</Button>
                        </LegacyStack>
                        </LegacyCard>
                    </Layout.Section>
                    </Layout>
                        
            </Page>
        );
}

export default Settings
